
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    mixins: [cms, playGames],
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            jackpot: 'cms/jackpot',
            jackpotVal: 'cms/jackpotVal',
        }),
        jackpotSlide() {
            const chunkSize = 4
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.jackpot?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.jackpot?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-jackpot-next').click()
        },
    },
}
